import { UserService } from './../../../data/services/user/user.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/data/services/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
/**
 * @description This guard prevents unauthenticated users from accessing certain routes
 */
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.userService.getUser()
    if(currentUser){

      //check if route is restricted by role
      if(route.data.roles && route.data.roles.indexOf(currentUser.role) === -1){

        // user not authorized --> redirect to home page
        this.router.navigate(['/'])
        return false;
      }else{
        //if no specific roles but user is logged in then return true
        return true
      }
    }
    //user is not logged in so redirect to login page 
    this.router.navigate(['/authentication'])
    
  }
}
