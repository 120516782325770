import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/root/app.module';
import { proxy } from 'package.json';
import { environment } from './environments/environment';

if (proxy) {
  enableProdMode();
  console.log("De Productie mode is ingeschakeld.")
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
