import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateCacheService } from 'ngx-translate-cache';
import { Subscription, Observable, fromEvent } from 'rxjs';
import { User } from 'src/app/data/models/user';
import { AuthenticationService } from 'src/app/data/services/authentication/authentication.service';
import { LanguageService } from 'src/app/data/services/language/language.service';
import { UserService } from 'src/app/data/services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  //The current user
  currentUser: User;

  //Displayed user name
  displayName: String;

  //Short display user name if user name exceeds 50 characters
  shortDisplayName: String;

  //Observable for screen changes
  resizeObservable$: Observable<any>;

  //Subscription for screen changes
  resizeSubscription$: Subscription;

  //Flag for mobile
  isMobile: boolean;

  //Flag for dropdown navbar mobile
  navbarOpen = true;

  //Flag for dropdown for the langauge menu
  taalMenuOpen = false;

  //Available Languages
  languages = ['nl', 'en'];

  //All the available languages expect the one selected
  dropdownLanguages = ['en'];

  //The selected language
  selectedLanguage = 'nl';

  //Subscription for user info
  private userInfoSub: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    translateCacheService: TranslateCacheService,
    private userService: UserService,
    private languageService: LanguageService
  ) {
    // translate.setDefaultLang('nl-BE');
    translateCacheService.init();

    this.currentUser = this.userService.getUser();
    this.displayName = authenticationService.getDisplayName();

    if (window.innerWidth <= 768) {
      this.isMobile = true;
    }
    this.resizeObservable$ = fromEvent(window, 'resize');
    this._listenForScreenChanges();

    // Login logout
  }

  /**
   * @description Logs the user out
   */
  logOut() {
    this.authenticationService.logout();
  }

  /**
   * @description Check if user is logged in
   */
  ngOnInit() {
    this.authenticationService.loggedInUser.subscribe((user) => {
      this.currentUser = user;
      if (this.currentUser != null) {
        this.userService.getById(this.currentUser.id).subscribe((data) => {
          this.setDisplayName(data);
        });
      }
    });
    this.initLanguageFromLocalStorage();
    this.userInfoSub = this.userService.userInfoChanged.subscribe(
      (user: User) => {
        this.setDisplayName(user);
      }
    );
  }

  /**
   * @description Listen for screen changes
   */
  private _listenForScreenChanges() {
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      if (evt.target.innerWidth <= 768) {
        // 768px portrait
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.navbarOpen = true;
      }
    });
  }

  /**
   * @description Initialise language from local storage
   * If language is not in localstorage then use default language 'nl'
   */
  initLanguageFromLocalStorage() {
    if (this.languageService.languageExistsInLocalStorage()) {
      this.selectedLanguage = this.languageService.getLanguageFromLocalStorage();
      this.changeLanguage(this.selectedLanguage);
    } else {
      this.translate.use('nl');
      this.languageService.setDefaultLanguageInLocalStorage();
    }
  }

  /**
   * @description Opens the language menu
   */
  toggleTaalMenuOpen() {
    this.taalMenuOpen = true;
    /*
      Returning false from an event handler will automatically call event.stopPropagation() and event.preventDefault()
      The advantage of this is that the hyperlink does not scroll to the top of the page again
    */
    return false;
  }

  /**
   * @description Closing the language menu
   */
  toggleTaalMenuClose() {
    this.taalMenuOpen = false;
    /*
      Returning false from an event handler will automatically call event.stopPropagation() and event.preventDefault()
      The advantage of this is that the hyperlink does not scroll to the top of the page again
    */
    return false;
  }
  /**
   * @description Opening and Closing the language menu
   */
  toggleTaalMenuOpenClose($e) {
    this.taalMenuOpen = !this.taalMenuOpen;
    /*
      Returning false from an event handler will automatically call event.stopPropagation() and event.preventDefault()
      The advantage of this is that the hyperlink does not scroll to the top of the page again
    */
    return false;
  }

  /**
   * @description Changes the text of the selected language to the selected language
   * @param language The selected language
   */
  useLanguage(language: string) {
    this.translate.use(language);
    this.changeLanguage(language);
  }

  /**
   * @description Changes the language in @param selectedLanguage and
   *              modifies the languages in @this dropdownLanguages
   * @param language The selected language
   */
  changeLanguage(language: string) {
    localStorage.setItem('language', language);
    this.selectedLanguage = localStorage.getItem('language');
    this.dropdownLanguages = this.languages.filter(
      (e) => e !== this.selectedLanguage
    );
  }

  /**
   * @description Set the name after login in the header, checks which role the user has
   * @param data
   */
  setDisplayName(data) {
    switch (this.currentUser.role) {
      case 'User': {
        this.displayName = data.firstName + ' ' + data.name;
        this.shortDisplayName = this.displayName.substring(0, 50) + ' ...';
        break;
      }
      case 'Company': {
        this.displayName = data.companyName;
        this.shortDisplayName = this.displayName.substring(0, 50) + ' ...';
        break;
      }
    }
  }

  /**
   * @description close the header if an option is clicked in mobile version and scrolls back to the top of the page
   */
  onActivate() {
    window.scrollTo(0, 0);
    if (this.isMobile) {
      this.navbarOpen = !this.navbarOpen;
    }
  }

  /**
   * @description Unsubscribe from user info
   */
  ngOnDestroy(): void {
    this.userInfoSub.unsubscribe();
  }

  /**
   * @description Opens the dropdown
   */
  openDropdown() {
    this.navbarOpen = true;
  }
}
