import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { RequestOptions, Request, Headers } from '@angular/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmailRequest } from '../../models/emailRequest';
import { UpdatePasswordRequest } from '../../models/UpdatePasswordRequest';
import { User } from '../../models/user';
import { proxy } from 'package.json';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnInit {
  requestOptions: RequestOptions;
  apiUrl = proxy;
  userInfoChanged = new Subject<User>();

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.requestOptions = new RequestOptions({
      headers: null,
      withCredentials: true,
    });
  }

  /**
   * @description returns all users
   */
  getAll() {
    return this.http.get<any>(`${this.apiUrl}/user`);
  }

  /**
   * @description Saves user data such as the JWT token, email adress etc... in local storage
   * @param userData Data from the user
   */
  storeUserData(userData) {
    localStorage.setItem('userData', JSON.stringify(userData));
  }

  /**
   * @description returns the current user (from localstorage)
   */
  getUser() {
    let jsonObj: any = JSON.parse(localStorage.getItem('userData'));
    let user: User = <User>jsonObj;
    return user;
  }

  /**
   * Notify all the subscriptions of 'userInfoChanged' that the user changed
   * @param user the updated user
   */
  updateUserNotification(user: User) {
    this.userInfoChanged.next(user);
  }

  //* Send the updated user (student of company) to the server
  updateUser(user: User) {
    return this.http.put<any>(`${this.apiUrl}/user/${user.id}`, user);
  }

  /**
   * @description returns student or company by id
   * @param id the id from the user
   */
  getById(id: number) {
    return this.http.get<any>(`${this.apiUrl}/user/${id}`);
  }

  //* sends a mail with a link to reset password
  forgotPassword(object: EmailRequest)
  {
    return this.http.post<any>(`${this.apiUrl}/user/password`, object);
  }

  //* resets password to new
  resetPassword(object: UpdatePasswordRequest)
  {
        const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': object.token
        })
      };
      return this.http.put<any>(`${this.apiUrl}/user/password/reset`, object,  httpOptions)
  }

  /**
   * Set isFavoritesInfoClosed cookie to true
   */
  setIsFavoritesInfoClosed(){
    localStorage.setItem('isFavoritesInfoClosed',JSON.stringify(true));
  }

  /**
   * checks if isFavoritesInfoClosed cookie is true or false
   * @returns true or false
   */
  getIsFavoritesInfoClosed(){
    return JSON.parse(localStorage.getItem('isFavoritesInfoClosed'));
  }
}
