import { Component, OnInit } from '@angular/core';
import { FilterService } from 'src/app/data/services/filter/filter.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(private filterService: FilterService) {}

  ngOnInit(): void {}

  /**
   * @description checks the checkbox
   */
  setCheckbox(name: string) {
    this.filterService.setCheckbox(name);
  }
  /**
   * scroll to top of the page
   */
  onActivate() {
    window.scrollTo(0, 0);
  }
}
