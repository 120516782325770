<nav class="navbar navbar-expand-lg navbar-light bg-light shadow fixed-top">
  <!-- Hamburger Icon -->
  <button
    class="navbar-toggler hamburger"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNavDropdown"
    aria-controls="navbarNavDropdown"
    aria-expanded="false"
    aria-label="Toggle navigation"
    (click)="openDropdown()"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <a
    routerLink="/"
    class="navbar-brand"
    title="Home"
    rel="home"
    (click)="onActivate()"
  ><img class="vivesLogo" src="../../assets/images/logo_black.svg" alt="VIVES hogeschool"></a>

  <li style="list-style: none; color: white;"
  *ngIf="currentUser && currentUser.role === 'Company'"
  class="nav-item">
  <a
    class="vacancyBtn"
    routerLink="/vacancies/create"
    routerLinkActive="active"
    (click)="onActivate()"
    >Vacature aanmaken</a>
</li>
&nbsp;
&nbsp;

  <div
    class="navbar-collapse collapse"
    id="navbarNavDropdown"
    *ngIf="navbarOpen"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active"
          routerLink="/vacancies"
          (click)="onActivate()"
          >{{ "navbar.overview" | translate }}
          <span class="sr-only">(current)</span></a
        >
      </li>
      <li *ngIf="currentUser && currentUser.role === 'User'" class="nav-item">
        <a
          class="nav-link"
          routerLink="/vacancies/favorites"
          routerLinkActive="active"
          (click)="onActivate()"
          >{{ "navbar.favorieten" | translate }}</a
        >
      </li>
      <li
        *ngIf="currentUser && currentUser.role === 'Company'"
        class="nav-item"
      >
        <a
          class="nav-link"
          routerLink="/vacancies/companyVacancies"
          routerLinkActive="active"
          (click)="onActivate()"
          >{{ "navbar.eigenvac" | translate }}</a
        >
      </li>

      <!-- Over Ons pagina eventueel nog aanmaken -->
      <!-- 
              <li class="nav-item">
              <a class="nav-link" href="#">{{"navbar.about" | translate}}</a>
            </li> -->
      <li class="nav-item">
        <a
          class="nav-link"
          href="https://www.vives.be/nl/studeren/verder-studeren-na-je-studies-aan-vives"
          target="_blank"
          >{{ "navbar.studying" | translate }}</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="/navorming"
          routerLinkActive="active"
          (click)="onActivate()"
        >
          {{ "navbar.research" | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a
          routerLink="/contact"
          routerLinkActive="active"
          class="nav-link"
          (click)="onActivate()"
          >Contact</a
        >
      </li>
      <li class="nav-item">
        <a
          routerLink="/faq"
          routerLinkActive="active"
          class="nav-link"
          (click)="onActivate()"
          >F.A.Q.</a
        >
      </li>
    </ul>
  </div>
  <!-- Authentication, Profile and Language Container -->
  <div
    class="bottomContainer navbar-collapse collapse"
    id="navbarNavDropdown"
    *ngIf="navbarOpen"
  >
    <ul class="navbar-nav registreermenu">
      <div class="registreerdiv">
        <div *ngIf="!currentUser">
          <li class="nav-item registrerenbtnwrapper">
            <a
              routerLink="/authentication"
              routerLinkActive="active"
              class="registrerenbtn"
              (click)="onActivate()"
            >
              Login
            </a>
          </li>
        </div>

        <div *ngIf="!currentUser">
          <li class="nav-item registrerenbtnwrapper">
            <a
              routerLink="/authentication/register"
              routerLinkActive="active"
              class="registrerenbtn2"
              (click)="onActivate()"
            >
              Registreren
            </a>
          </li>
        </div>



        <div style="width: 100%;" *ngIf="currentUser" class="loggedin-wrapper">
          <li class="nav-item registrerenbtnwrapper">
            <a class="registrerenbtn" routerLink="" (click)="logOut()" href>{{
              "navbar.logout" | translate
            }}</a>
          </li>
          <br>
          <li class="">
            <a
              *ngIf="
                displayName !== null && displayName.length <= 50;
                else showShortDisplayName
              "
              routerLink="/profile"
              routerLinkActive="active"
              class="profiel-bewerken"
              (click)="onActivate()"
              style="margin-right: 1rem;"
              >{{ displayName }}</a
            >
            <a
              *ngIf="displayName !== null && displayName.length > 50"
              #showShortDisplayName
              routerLink="/profile"
              routerLinkActive="active"
              class="profiel-bewerken"
              (click)="onActivate()"
              >{{ shortDisplayName }}</a
            >
          </li>
        </div>
      </div>

      <div class="wrappertaalmenu" (mouseleave)="toggleTaalMenuClose()"></div>
      <div
        class="taalmenu"
        (mouseover)="toggleTaalMenuOpen()"
        (click)="toggleTaalMenuOpenClose($event)"
      >
        <li class="nav-item dropdown taaldropdown">
          <a
            class="nav-link dropdown-toggle taalselect"
            href="#"
            id="navbarDropdownTaalMenuLink"
            (click)="useLanguage(selectedLanguage)"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ selectedLanguage }}
          </a>
          <div
            class="dropdown-menu dropdown-taalselect"
            aria-labelledby="navbarDropdownMenuLink"
            [ngClass]="{ show: taalMenuOpen }"
          >
            <a
              *ngFor="let language of dropdownLanguages"
              class="dropdown-item dropdown-taal"
              id="language1"
              href="#"
              (click)="useLanguage(language)"
              >{{ language }}</a
            >
          </div>
        </li>
      </div>
    </ul>
  </div>
</nav>
