import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }

  /**
   * gets the selected language from localstorage
   * @returns the language from local storage (en or nl)
   */
  getLanguageFromLocalStorage(){
    return localStorage.getItem('language');
  }

  /**
   * checks if language cookie has been made in the localstorage
   * @returns true or false
   */
  languageExistsInLocalStorage(){
    return localStorage.getItem('language') != null && localStorage.getItem('language') != undefined;
  }

  /**
   * creates a standard language cookie in localstorage set to nl
   */
  setDefaultLanguageInLocalStorage(){
    localStorage.setItem('language','nl')
  }


}
