import { AuthenticationGuard } from './../core/guard/authentication/authentication.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from '../modules/contact/page/contact/contact.component';
import { UploadComponent } from '../modules/upload/upload.component';
import { Role } from '../data/models/role'

/**
 * @description Consists of routes to every module
 * Every module contains a routing module with its routes
 */
const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('../modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'vacancies',
    loadChildren: () =>
      import('../modules/vacancy/vacancy.module').then((m) => m.VacancyModule),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('../modules/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('../modules/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('../modules/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'navorming',
    loadChildren: () =>
      import('../modules/further-training/further-training.module').then(
        (m) => m.FurtherTrainingModule
      ),
  },
  {
    path: 'profile',
    canActivate:[AuthenticationGuard],
    loadChildren: () =>
      import('../modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'policy',
    loadChildren: () =>
      import('../modules/policy/policy.module').then(
        (m) => m.PolicyModule
      ),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  { 
    path: 'notfound',  
    loadChildren: () =>
      import('../modules/notfound/notfound.module').then((m) => m.NotFoundModule),
  },
  { path: '**', redirectTo: 'notfound'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
